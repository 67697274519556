<template>
  <div class="page" id="afterCall">
    <div class="mb-1">
      <h4 class="mt-0">
        {{ $t('settings.callSettings.defaultSurvey.title') }}
      </h4>
      <div class="d-flex select-container">
        <Select
          class="flex-fill"
          v-model="postCallSurvey"
          :options="postCallSurveys"
          :searchable="true"
          @search="
            name =>
              fetchSurveys({
                name
              })
          "
        >
          <template #afterList>
            <b-spinner small v-if="surveysLoading"></b-spinner>
          </template>
        </Select>
        <b-button
          class="px-3 py-2"
          @click="reset"
          variant="danger"
          :disabled="updatePostCallDefaultSurveyLoading || !postCallSurvey"
        >
          {{
            updatePostCallDefaultSurveyLoading
              ? $t('settings.callSettings.defaultSurvey.reseting')
              : $t('settings.callSettings.defaultSurvey.reset')
          }}
        </b-button>
      </div>
    </div>
    <b-button
      class="px-3 py-2"
      @click="save"
      variant="primary"
      :disabled="updatePostCallDefaultSurveyLoading"
    >
      {{
        updatePostCallDefaultSurveyLoading
          ? $t('settings.callSettings.defaultSurvey.saving')
          : $t('settings.callSettings.defaultSurvey.save')
      }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SettingsService from '../../../../services/settings.service'
import PostCallSurveyService from '../../../../services/post-call-survey.service'

export default {
  name: 'Settings',
  components: {},
  computed: {
    ...mapGetters(['currentTenant'])
  },
  data() {
    return {
      updatePostCallDefaultSurveyLoading: false,
      postCallSurvey: null,
      postCallSurveys: [],
      surveysLoading: false
    }
  },
  methods: {
    ...mapActions(['fetchCurrentUser']),
    async fetchSurveys(filters = {}) {
      try {
        this.surveysLoading = true
        const res = await PostCallSurveyService.fetchPostCallSurveys({
          ...this.postCallSurveyFilters,
          ...filters
        })
        this.postCallSurveys = res.data.surveys
      } catch (error) {
        console.log(error)
      } finally {
        this.surveysLoading = false
      }
    },
    async reset() {
      try {
        this.updatePostCallDefaultSurveyLoading = true
        await SettingsService.updatePostCallDefaultSurvey(null)
        this.postCallSurvey = null
        this.toast(
          this.$t(
            'settings.callSettings.defaultSurvey.toasts.default-survey-reset'
          )
        )
        this.fetchCurrentUser()
      } catch (error) {
        console.log(error)
      } finally {
        this.updatePostCallDefaultSurveyLoading = false
      }
    },
    async save() {
      try {
        this.updatePostCallDefaultSurveyLoading = true
        await SettingsService.updatePostCallDefaultSurvey(
          this.postCallSurvey.id
        )
        this.toast(
          this.$t(
            'settings.callSettings.defaultSurvey.toasts.default-survey-saved'
          )
        )
        this.fetchCurrentUser()
      } catch (error) {
        console.log(error)
      } finally {
        this.updatePostCallDefaultSurveyLoading = false
      }
    },
    async getDefaultSurvey() {
      if (this.currentTenant && this.currentTenant.defaultSurvey) {
        try {
          const res = await PostCallSurveyService.getOneSurvey(
            this.currentTenant.defaultSurvey
          )
          this.postCallSurvey = res.data.survey
        } catch (error) {
          console.log(error)
        }
      } else {
        this.postCallSurvey = null
      }
    }
  },
  watch: {
    currentTenant: {
      immediate: true,
      handler() {
        if (!this.currentTenant) return
        this.getDefaultSurvey()
        this.fetchSurveys()
      }
    }
  },
  mounted() {
    this.fetchSurveys()
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1rem;
}
.select-container {
  gap: 0.5rem;
}
</style>
